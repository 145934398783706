<template>
  <lkps-table
    :apiEndpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_pldp',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Tahun Akademik',
          dataKey: 'tahun_akademik',
          attributes: { rowspan: 2, width: '250px' },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Jenis Layanan dan Pembinaan',
          attributes: { colspan: 6, }
        }],
        [{
          dataKey: 'jenis_program_layanan_dan_pembinaan_minat',
          label: 'Minat',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          dataKey: 'jenis_program_layanan_dan_pembinaan_bakat',
          label: 'Bakat',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          dataKey: 'jenis_program_layanan_dan_pembinaan_penalaran',
          label: 'Penalaran',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          dataKey: 'jenis_program_layanan_dan_pembinaan_kesejahteraan',
          label: 'Kesejahteraan',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }, {
          dataKey: 'jenis_program_layanan_dan_pembinaan_keprofesian',
          label: 'Keprofesian',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'text',
          
          placeholder: `${moment().format('YYYY')}/${moment().add(1, 'years').format('YYYY')}`
        }, {
          label: 'Jenis Program Layanan dan Pembinaan Minat',
          name: 'jenis_program_layanan_dan_pembinaan_minat',
          dataKey: 'jenis_program_layanan_dan_pembinaan_minat',
          type: 'text',
        }, {
          label: 'Jenis Program Layanan dan Pembinaan Bakat',
          name: 'jenis_program_layanan_dan_pembinaan_bakat',
          dataKey: 'jenis_program_layanan_dan_pembinaan_bakat',
          type: 'text',
        }, {
          label: 'Jenis Program Layanan dan Pembinaan Penalaran',
          name: 'jenis_program_layanan_dan_pembinaan_penalaran',
          dataKey: 'jenis_program_layanan_dan_pembinaan_penalaran',
          type: 'text',
        }, {
          label: 'Jenis Program Layanan dan Pembinaan Kesejahteraan',
          name: 'jenis_program_layanan_dan_pembinaan_kesejahteraan',
          dataKey: 'jenis_program_layanan_dan_pembinaan_kesejahteraan',
          type: 'text',
        }, {
          label: 'Jenis Program Layanan dan Pembinaan Keprofesian',
          name: 'jenis_program_layanan_dan_pembinaan_keprofesian',
          dataKey: 'jenis_program_layanan_dan_pembinaan_keprofesian',
          type: 'text',
        }]
      }
    }
  },
}
</script>
